<template>
  <div class="single-traffic-load-detail manage-scroll">
    <div class="module-wrapper-detail">
      <el-button size="small" class="back" @click="back"><i class="iconfont icon-fanhui mr-2 align-middle"></i>返回</el-button>
      <div class="page-left">
        <div class="l-t">
          <div class="page-title">
            <img src="../../../../../assets/images/single/van.png" alt="">
            <strong>车辆基本信息</strong>
          </div>
          <div class="l-t-con">
            <c-des
                :desItems="desItems1"
                v-model="carData.carInfo"
                :column="2"
                :border="false"
                size="small"
                labelClassName="detail-label"
                contentClassName="detail-content"
            ></c-des>
          </div>
        </div>
        <div class="l-b">
          <div class="page-title">
            <img src="../../../../../assets/images/single/van.png" alt="">
            <strong>车辆照片</strong>
          </div>
          <div class="l-b-con">
            <img :src="carImg" alt=""/>
          </div>
        </div>
      </div>
      <div class="page-right">
        <div class="p-r-tit">车辆详情展示</div>
        <div class="wheel-list">
          <div class="wheel-list-con">
            <div class="w-l-con" v-for="(item,index) in carData.carDetail" :key="index">
              <div class="w-l" v-for="(con,i) in item" :key="i">
                <div class="w-text">轮重：<span>{{con.left}}kg</span></div>
                <div class="l-line">
                  <span></span>
                  <i></i>
                </div>
                <div class="wheel-img">
                  <img src="../../../../../assets/images/single/wheel.png" alt="">
                </div>
                <div class="r-line">
                  <i></i>
                  <span></span>
                </div>
                <div class="w-text">轮重：<span>{{con.right}}kg</span></div>
                <div class="axis-space" v-if="con.axle">
                  <i></i>
                  <span></span>
                  <div class="w-text">轴间距：<span>{{con.axle}}mm</span></div>
                </div>
              </div>
              <div class="axis"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Configs from "./config/detail";

  export default {
    data() {
      let {desItems1, desItems2, desItems3} = Configs(this);
      return {
        carData: {
          carDetail: [],
          carInfo: {},
        },
        wheelList: [
          [{key: 1, left: 2160, right: 2160, axis: 3280}],
          [{key: 2, left: 2160, right: 2160, axis: 3280},{key: 3, left: 2160, right: 2160, axis: 3280}],
          [{key: 4, left: 2160, right: 2160, axis: 3280},{key: 5, left: 2160, right: 2160, axis: 3280}, {key: 6, left: 2160, right: 2160, axis: 3280}],
          [{key: 7, left: 2160, right: 2160, axis: 3280}, {key: 8, left: 2160, right: 2160}]
        ],
        desItems1,
        desItems2,
        desItems3,
        carImg: "../../../../../assets/images/single/brigde.jpg"
      };
    },
    created() {
      this.getDetail()
    },
    methods: {
      getDetail() {
        this.$http.get("/car/getCarById", {
          params: {
            id: this.$route.params.id,
            type: this.$route.query.type,
        }}).then(res => {
          if (res.success) {
            if (res.data) {
              if (res.data) {
                this.carData = res.data
                let datetime = this.carData.carInfo.checkTime;
                let picNum = this.carData.carInfo.picNum;
                let roadLine = this.carData.carInfo.laneNum;
                this.carImg = window.location.origin+window.location.pathname
                    +localStorage.getItem("baseUrl")+"/carimg/"
                    +datetime.substring(0,10).replaceAll("-","/")+"/"+picNum+"_"+roadLine+".jpg";
              }
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      back() {
        this.$router.go(-1)
      }
    },
  };
</script>
<style scoped>
  .module-wrapper-detail {
    display: flex;
  }
  .back{
    position: fixed;
    top: 106px;
    right: 36px;
  }
  .page-left {
    width: 35%;
    height: 100%;
    border-right: 1px solid #E9E9E9;
    padding: 0 50px 0 20px;
  }
  .page-title {
    width: 100%;
    height: 45px;
    line-height: 45px;
    display: flex;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #E9E9E9;
  }
  .page-title img {
    width: 20px;
    height: 18px;
    margin-right: 10px;
  }
  .page-title strong {
    color: #1E1E1E;
    font-size: 18px;
  }
  .l-t-con {
    padding: 10px 0;
  }
  /deep/ .el-descriptions--small:not(.is-bordered) .el-descriptions-item__cell {
    width: 60%;
  }
  /deep/ .el-descriptions--small:not(.is-bordered) .el-descriptions-item__cell:first-child {
    width: 40%;
  }
  /deep/ .el-descriptions-item__container {
    line-height: 30px;
    font-size: 14px;
  }
  /deep/ .el-descriptions-item__label:not(.is-bordered-label) {
    width: 70px;
  }
  /deep/ .el-descriptions-item__container .el-descriptions-item__content {
    color: #2C74E1;
  }
  .l-b-con {
    padding-top: 10px;
  }
  .page-right {
    width: 65%;
  }
  .p-r-tit {
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #1E1E1E;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .wheel-list {
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
  }
  .wheel-list::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
  }
  .wheel-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  .wheel-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #999;
  }
  .wheel-list-con{
    width: 100%;
    position: relative;
  }
  .w-l-con {
    width: 100%;
    margin-bottom: 60px;
  }
  .w-l-con:last-child {
    margin-bottom: 0;
  }
  .w-l {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
  }
  .w-text {
    padding: 10px;
    border: 1px solid #6F95DA;
    color: #5A5A5A;
    font-size: 14px;
    margin-top: -20px;
  }
  .w-text span {
    color: #2C74E1;
  }
  .wheel-img {
    width: 35%;
    height: 90px;
    margin:  0 20px;
  }
  .wheel-img img {
    width: 100%;
    height: 100%;
  }
  .l-line,.r-line{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    margin-top: -20px;
  }
  .l-line span,.r-line span{
    display: flex;
    width: 50px;
    height: 1px;
    background: #6F95DA;
  }
  .l-line i{
    display: block;
    width: 15px;
    height: 15px;
    border-bottom: 1px solid #6F95DA;
    transform: rotate(45deg);
    position: absolute;
    right: -18px;
    top: -6px;
  }
  .r-line i{
    display: block;
    width: 15px;
    height: 15px;
    border-bottom: 1px solid #6F95DA;
    transform: rotate(135deg);
    position: absolute;
    left: -8px;
    top: 4px;
  }
  .axis{
    width: 2px;
    height: calc(100% - 90px);
    background: #6F95DA;
    position: absolute;
    left: 40%;
    top: 42px;
  }
  .axis-space{
    display: flex;
    align-content: center;
    align-items: center;
    position: absolute;
    left: 40%;
    top: 60px;
    margin-left: -4px;
  }
  .axis-space i{
    display: block;
    width: 10px;
    height: 10px;
    background: #6F95DA;
    border-radius: 50%;
  }
  .axis-space>span{
    display: block;
    width: 30px;
    height: 1px;
    background: #6F95DA;
  }
  .axis-space .w-text{
    margin-top: 0;
  }
</style>
